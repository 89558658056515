import React from 'react'
import Typography from '@mui/material/Typography';
import "../style/home.css"
import logo_img1 from "../images/logo_img.png";
import logo_img2 from "../images/Goenka Global logo_An Initiative of.jpg"
import logo_img3 from "../images/EduCluster logo.png";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export default function Footer() {
  return (
    <div className='footer_class'>
        <div className='fist_cont'>
            <div className='logo1'>
                <img src={logo_img1} alt='some new containt'  />
            </div>
            <div className='logo2'>
                <img src={logo_img2}  alt='some new containt' />
            </div>
            <div className='logo3'>
                <img src={logo_img3} alt='some new containt' />
            </div>
        </div>
        <div className='sec_cont'>
            <div className='new_icon_text_section'> 
             <LocationOnIcon sx={{margin:"0 15px 0 0"}}/>
            <Typography variant='h4'>
                Finland International School, Thane<br></br>
                Road no. 27, Shreenagar, Wagle Estate, 
                Thane West, Maharashtra - 400604
            </Typography>
            </div>

            <div className='new_icon_text_section'>
            <CallIcon sx={{margin:"0 15px 0 0"}}/>
            <Typography variant='h4'>
            +91 84960 60000
            </Typography>
            </div>

            <div className='new_icon_text_section'>
            <CalendarTodayIcon sx={{margin:"0 15px 0 0"}}/>
            <Typography variant='h4'>
                Mon - Sat : 08.00 AM - 05.30 PM
            </Typography>
            </div>
        </div>
    </div>
  )
}
