import React from 'react'
import TitleSection from './TitleSection'
import { Typography } from '@mui/material'
// import extra_img1 from "../images/extre_img1.jpg"
// import extra_img2 from "../images/extra_img2.jpg"
import extra_img3 from "../images/art_design_img.jpeg"
import extra_img4 from "../images/Coding.jpg"
import extra_img5 from "../images/cricket-player-getting-ready-play.jpg"
import extra_img6 from "../images/Dancing.jpg"
import extra_img7 from "../images/Football.jpg"
import extra_img8 from "../images/horse riding.jpg"
import extra_img9 from "../images/LEGO.jpg"
import extra_img10 from "../images/Music.jpg"
import extra_img11 from "../images/tech_img.jpeg"
import extra_img12 from "../images/texttile_im g.jpeg"
import extra_img13 from "../images/Woodwork.jpg"
import Slider from "react-slick";
import sec_left_img from "../images/sec_icon.png"
import { AnimatedOnScroll } from "react-animated-css-onscroll";


export default function ExtracActivities() {
    var settings = {
    autoplay: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // arrow: true
      };
  return (
    <div className='extra_activities_main' id='Extracur_activities_id'>
        <div className="sec_icon_left">
        <img src={sec_left_img} alt="side logo"/>
      </div>
        <TitleSection title={"Extracurricular Activities"} />
        <AnimatedOnScroll
                animationIn="fadeInDown"
                animationInDelay={50}
                animationInDuration={1000}
                className='container_extra_acti'
            >
        {/* <div className='container_extra_acti' 
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="1000"
                > */}
            <div className='extra_act_cont'>
            <Slider {...settings}>

                <img src={extra_img3} alt='some new containt' />
                <img src={extra_img9} alt='some new containt' />
                <img src={extra_img10} alt='some new containt' />
                <img src={extra_img12} alt='some new containt' />
                <img src={extra_img13} alt='some new containt' />
            </Slider>
                <Typography variant="h3" >
                    Art & Design
                </Typography>
            </div>

            <div className='extra_act_cont'>
            <Slider {...settings}>
            <img src={extra_img5} alt='some new containt' />
            <img src={extra_img6} alt='some new containt' />
            <img src={extra_img7} alt='some new containt' />
            <img src={extra_img8} alt='some new containt' />
            </Slider>
                <Typography variant="h3" >
                Physical Activities
                </Typography>
            </div>

            <div className='extra_act_cont'>
            <Slider {...settings}>
            <img src={extra_img4} alt='some new containt' />
            <img src={extra_img11} alt='some new containt' />
            {/* <img src={extra_img10} alt='some new containt' /> */}
            </Slider>
                <Typography variant="h3" >
                Tech Excellence
                </Typography>
            </div>
        {/* </div> */}
        </AnimatedOnScroll>
    </div>
  )
}
