import React, { useState } from "react";
import TitleSection from "./TitleSection";
import { Button, Typography, Box } from "@mui/material";
import about_us_banner from "../images/about_us_banner.jpeg";
import about_img1 from "../images/FINnishteching.png";
import about_img2 from "../images/S-502.jpg";
import about_img3 from "../images/Strong Academic Foundation.jpg";
import about_img4 from "../images/Nurturing Environment.png";
import about_img5 from "../images/Holistic Development.png"
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Slider from "react-slick";
import "../style/home.css";
import sec_left_img from "../images/sec_icon.png"
import { AnimatedOnScroll } from "react-animated-css-onscroll";

const style = {
    padding: "50px 30px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs:300,md:600},
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
//   p: 4,
  // paddingTop: '0',
};

export default function AboutUs() {
  // var settings = {
  //     dots: false,
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 2,
  //     slidesToScroll: 1,
  //     // autoplay: true,
  //   };

  var settings = {
    autoplay: true,
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          //   dots: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [indNumber, setInd] = useState(0);
  // eslint-disable-next-line
  const [divValue, setValue] = useState([
    {
      about_img_tit: "Finnish Teaching Methodology",
      title_p: ["A student-centric approach, inspired by Finland’s model",
        "Collaborative Personal Learning Plans",
        "Hands-on, real-world experiences and teamwork", 
        "Reduced homework and a stress-free environment",
        "Grounded in research-based education."
        ],
      img_url: about_img1,
    },
    {
      about_img_tit: "Digital Platform Learning",
      title_p: ["Seamlessly integrating platforms into the curriculum",
        "Essential digital skills",
        "Preparing for a technology-driven future",
        "Cultivating tech-savvy students who master technology rather than being passive users"
        ],
      img_url: about_img2,
    },
    {
      about_img_tit: "Strong Academic Foundation",
      title_p: ["Rigorous curriculum",
        "Dedicated educators",
        "Successful test results",
        "Preparing students for excellence and college readiness"
        ],
      img_url: about_img3,
    },
    {
      about_img_tit: "Nurturing Environment",
      title_p:["A safe, inclusive, and supportive space where every student is valued",
        "Instil essential values",
        "Counselling services",
        "Actively involve parents",
        "Promote a culture of respect"
        ],
      img_url: about_img4,
    },
    {
      about_img_tit: "Holistic Development",
      title_p: ["Diverse extracurricular activities",
        "Nurturing well-rounded education",
        "Emphasis on physical health, arts, community service, and leadership development"
        ],
      img_url: about_img5,
    },
  ]);
  // const handleOpen = (ind) => {
  //   // console.log(setValue(), "open index --===== ");
  //   setOpen(true);
  //   setInd(ind);
  // };
  console.log("open index ", indNumber, divValue);
  const handleClose = () => setOpen(false);

  // const title_li = divValue

  return (
    <div className="about_section_con" id="About_id">
      <div className="sec_icon_left">
        <img src={sec_left_img} alt="side logo"/>
      </div>


      <Modal
      className="model_aboutus"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box 
        sx={style} 
        // sx={{xs: ,md:style}}
        >
          <div className="close_div">
            <p onClick={handleClose}>
              <CloseIcon sx={{width:"30px",height:"30px"}} />
            </p>
          </div>
          <div className="model_picture">
            <img
              src={divValue[indNumber]?.img_url}
              alt={divValue[indNumber]?.about_img_tit}
            />
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {divValue[indNumber]?.about_img_tit}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <ul>
            {divValue[indNumber]?.title_p?.map((data)=>
               <b>
                   <li className="li_model">{data}</li>
               </b> 
            )
            }
                
            </ul>
          </Typography>
        </Box>
      </Modal>

      <TitleSection title={"About Us"} />
      <div className="about_main_div">
      <AnimatedOnScroll
                animationIn="fadeInUp"
                animationInDelay={50}
                animationInDuration={1000}
                className='banner_about_container' 
            >
        {/* <div
          className="banner_about_container"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
        > */}
          <img src={about_us_banner} alt="some new containt" />
        {/* </div> */}
        </AnimatedOnScroll>

        <AnimatedOnScroll
                animationIn="fadeInDown"
                animationInDelay={50}
                animationInDuration={1000}
                className='about_paragraph'
            >
        {/* <div
          className="about_paragraph"
          data-aos="fade-down"
          data-aos-delay="50"
          data-aos-duration="1000"
        > */}
          <Typography variant="h4" className="">
            Welcome to Finland International School – where excellence is a way
            of life.
          </Typography>

          <Typography variant="h4" className="">
            We are proud to be the authorised Learning Methodology Partner of
            EduCluster Finland (ECF), a leader in educational innovation.
            Through this unique collaboration, we bring the essence of Finnish
            education to your child’s learning journey.
          </Typography>

          <Typography variant="h4" className="">
            At FIS, we believe that every child's journey is unique, and that
            education should be a personalized adventure. Our commitment to
            nurturing individual strengths, fostering creativity, and building
            critical thinkers is at the core of our philosophy.
          </Typography>
        {/* </div> */}
        </AnimatedOnScroll>

        <AnimatedOnScroll
                animationIn="fadeInLeft"
                animationInDelay={50}
                animationInDuration={1000}
                className='talk_to_us_container'
            >
        {/* <div className="talk_to_us_container"> */}
          <Button className="talk_btn" variant="contained">
           <a className="btn_achore" href="#banner_id">
             Talk to us today!
            </a>
          </Button>
          <div className="img_abt_cont">
            <Slider {...settings}>
              {divValue &&
                divValue.length > 0 &&
                divValue.map((abt, ind) => (
                  <div
                    className="img_about"
                  >
                    <img src={abt.img_url} alt="some new containt" />
                    <div className="clas_div_abt">
                      <Typography className="text_cont_abot" variant="h3">
                        <ul>
                          {console.log("Loading ==== >> ",abt)}
                            {abt.title_p.map((abt_new, ind) =>(<>
                           
                          <li>
                            {abt_new}
                          </li>
                            
                            </>))}
                        </ul>
                        {/* <div className="read_more_main">
                          <Button
                            data-ind={ind}
                            onClick={(ele, ind) => {
                              // console.log("first --  ",ind,"  === new_icon ==",ele.target.dataset.ind)
                              handleOpen(ele.target.dataset.ind);
                            }}
                            className="btn_read_more"
                            variant="contained"
                          >
                            Read More
                            <PlayArrowIcon
                              sx={{
                                transform: "rotate(90deg)",
                                padding: "0 5px",
                                width: "35px",
                                height: "35px",
                                fill: "#1873d1",
                              }}
                            />
                          </Button>
                        </div> */}
                      </Typography>
                    </div>
                    <div className="about_img_tit">
                      <Typography variant="h4">{abt.about_img_tit}</Typography>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        {/* </div> */}
        </AnimatedOnScroll>
      </div>
    </div>
  );
}
