import React, { useState } from 'react'
import TitleSection from './TitleSection'
import { Button, Typography } from '@mui/material'
import "../style/home.css";
import finish_img1 from "../images/fin_img_1.png"
import finish_img2 from "../images/fin2.png"
import finish_img3 from "../images/collaborative.jpg"
import finish_img4 from "../images/travser.jpg"
import finish_img5 from "../images/childrens.jpg"
import sec_img from "../images/sec_icon.png"
import Slider from "react-slick";
import { AnimatedOnScroll } from "react-animated-css-onscroll";


export default function FinishAge() {

    var settings = {
        // dots: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            //   dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
// eslint-disable-next-line
      const [finnish, setfinnish] = useState([
        {
          finish_tit: "Unique Finnish Learning Methodology",
          title_p: ["Personalised learning experience, fostering a love for education that lasts a lifetime",
          "Students actively participate in their own learning, seeking and reflecting on their goals",
          "Students collaborate with teachers and guardians to create a Personal Learning Plan (PLPs) focusing on strengths and areas for improvement"
            ],
          img_url: finish_img1,
        },
        {
          finish_tit: "Play-Based and Experiential Learning",
          title_p: ["Vibrant Learning Environment: Our classrooms are dynamic spaces where play is the cornerstone of education",
          "Learning beyond textbooks, encouraging and creative exploration",
          "Emphasis on experiential learning to develop deep understanding, problem-solving skills, and critical thinking",
          "An approach that fosters creativity, innovation, and prepares students for an ever-evolving world "
            ],
          img_url: finish_img2,
        },
        {
          finish_tit: "Collaborative Learning Environment",
          title_p: ["Going beyond nurturing academic excellence to hone vital life skills such as teamwork, communication, and social development",
            "Collaborative projects, encouraging  brainstorming and effective communication",
            "Learning not just from teachers but also from each other, enriching their educational journey"
                     
            ],
          img_url: finish_img3,
        },
        {
          finish_tit: "Transversal Competences",
          title_p: ["A curriculum that focuses on transversal competences that extend beyond traditional academic knowledge",
          "Cultivating lifelong learners who can adapt, innovate, and pursue self-directed learning",
          "Foster global perspectives while celebrating India's rich cultural heritage",
          "Prioritise mental and physical well-being, time management, and other life skills",
          "Ensures proficiency in various forms of communication and mastery of technology"
            ],
          img_url: finish_img4,
        },
        {
          finish_tit: "Research-Based Approach",
          title_p: ["Quality Assurance Partner: Collaboration with EduCluster Finland (ECF), a part of the University of Jyväskylä Group, to co-develop innovative educational solutions based on the latest educational research",
          "Expert Finnish Teachers: An academic team of expert teachers and mentors from Finland who work closely with students",
          "Evidence-Based Teaching: Commitment to research-based practices to ensure effective teaching and learning"
          
            ],
          img_url: finish_img5,
        },
      ])

  return (
    <div className='finish_edge_main' id='Finnish_Edge_id'>
      <div className='sec_icon_right'>
            <img src={sec_img} alt="side logo"/>
        </div>
        <TitleSection title={"The Finnish Edge"}/>
        <AnimatedOnScroll
                animationIn="fadeInRight"
                animationInDelay={50}
                animationInDuration={1000}
                className='image_edge_container'
            >
        {/* <div className='image_edge_container' 
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="1000"> */}
             <Slider {...settings}>
            {finnish?.map((FinishAgedata)=>(
            
            <div className='img_finish'>
              <div className='finni_img_and_content'>
                <img src={FinishAgedata?.img_url} alt='some new containt'  />
                <div className='finish_details_data'>
                <ul>
                  {FinishAgedata?.title_p.map((Finish_li)=>(<li>{Finish_li}</li>))}
                </ul>
                </div>
              </div>
                <Typography variant='h4'>{FinishAgedata?.finish_tit}</Typography>
            </div>
            ))}
            {/* <div className='img_finish'>
                <img src={finish_img2} alt='some new containt'  />
                <Typography variant='h4'>Play-based and Experiential Learning</Typography>
            </div>
            <div className='img_finish'>
                <img src={finish_img3} alt='some new containt'  />
                <Typography variant='h4'>Collaborative Learning Environment</Typography>
            </div>
            <div className='img_finish'>
                <img src={finish_img4 } alt='some new containt' />
                <Typography variant='h4'>Transversal Competences</Typography>
            </div>
            <div className='img_finish'>
                <img src={finish_img5} alt='some new containt'  />
                <Typography variant='h4'>Research-Based Approach</Typography>
            </div> */}
            </Slider>

        {/* </div> */}
        </AnimatedOnScroll>
        <AnimatedOnScroll
                animationIn="fadeInLeft"
                animationInDelay={50}
                animationInDuration={1000}
                className='edge_container'
            >

        {/* <div className='edge_container' data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="1000"> */}
            {/* <Typography variant='h3'>
            Personalized learning experience, fostering a love for education that lasts a lifetime Students actively participate in their own learning,seeking and reflecting on their goals Students collaborate with teachers and guardians to create a Personal Learning Plan (PLPs) focusing on strengths and areas for improvement
            </Typography> */}
            <Button variant='contained'>
                <a href='#banner_id' className='btn_achore'>
                Know more
                </a>
            </Button>
        {/* </div> */}
        </AnimatedOnScroll>

    </div>
  )
}
