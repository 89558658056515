import React from 'react'
import HomeSlider from '../Components/HomeSlider'
import Academic from '../Components/Academic'
import AboutUs from '../Components/AboutUs'
import FinishAge from '../Components/FinishAge'
// import GlobleEducators from '../Components/GlobleEducators'
import Infrastructure from '../Components/Infrastructure'
import ExtracActivities from '../Components/ExtracActivities'
import FisHall from '../Components/FisHall'

export default function Home() {
  return (
    <div>
        <HomeSlider/>
        <Academic/>
        <AboutUs/>
        <FinishAge/>
        {/* <GlobleEducators/> */}
        <Infrastructure/>
        <ExtracActivities/>
        <FisHall/>
    </div>
  )
}
