import React from 'react'
import { useNavigate } from "react-router-dom";
import thankyou_img from "../images/FIS Thane - Thank you Page - Mobile Size.jpg"
import thankyou_img_desktop  from "../images/FIS Thane - Thank you Page- desktop.jpg"
import "../style/home.css"

export default function ThankYou() {

    const navigate = useNavigate();


    const function_redirect = () => {
        setTimeout(() => {
          // console.log('This will run after 1 second!')
          navigate("/")
        }, 6000);
      }

  return (
    <div className='thank_cls_div' onLoad={function_redirect}>
        <img src={thankyou_img_desktop} className='desktop_thnx' alt='some content' />
        <img src={thankyou_img} className='mobile_thnx'  alt='some content' />
    </div>
  )
}
