import { Button, Typography } from '@mui/material'
import React from 'react'
import "../style/home.css"
import TitleSection from './TitleSection'
import pre_primary from "../images/pre_primary.jpg"
import acde_img2 from "../images/image_academe2.jpg"
import acde_img3 from "../images/a-level.jpeg"
import acde_img4 from "../images/new_a_level.jpg"
import side_img from "../images/sec_icon.png"
import { AnimatedOnScroll } from "react-animated-css-onscroll";

export default function Academic() {
  return (
    <div className='academic_main_div' id='Academics_id'>
        <div className='sec_icon_right'>
            <img src={side_img} alt="side logo" />
        </div>
        <TitleSection title={"Academics"}/>

        <AnimatedOnScroll
                animationIn="fadeInLeft"
                animationInDelay={50}
                animationInDuration={1000}
                className='aca_main_container'
            >
        {/* <div className='aca_main_container'
         data-aos="fade-left"
                data-aos-delay="50"
                data-aos-duration="1000"> */}
            <div className='academic_img_cont'>
                <div className='academ_img'>
                    <img src={pre_primary} alt='some new containt' />
                </div>
                <div className='academic_img_titl'>
                    <Typography variant='h3'>
                        Pre-Primary
                    </Typography>
                </div>
            </div>
            <div className='academic_img_cont'>
                <div className='academ_img'>
                    <img src={acde_img2} alt='some new containt' />
                </div>
                <div className='academic_img_titl'>
                    <Typography variant='h3'>
                        Grade 1-6
                    </Typography>
                </div>
            </div>
            <div className='academic_img_cont'>
                <div className='academ_img'>
                    <img src={acde_img3} alt='some new containt' />
                </div>
                <div className='academic_img_titl'>
                    <Typography variant='h3'>
                        Grade 7-10
                    </Typography>
                </div>
            </div>
            <div className='academic_img_cont'>
                <div className='academ_img'>
                    <img src={acde_img4} alt='some new containt' />
                </div>
                <div className='academic_img_titl'>
                    <Typography variant='h3'>
                        A-Levels & IBDP
                    </Typography>
                </div>
            </div>
        {/* </div> */}
        </AnimatedOnScroll>

        <TitleSection title={"Boards Available"}/>

        <AnimatedOnScroll
                animationIn="fadeInRight"
                animationInDelay={50}
                animationInDuration={1000}
                className='btn_academic_container'
            >

        {/* <div className='btn_academic_container' data-aos="fade-right"
                data-aos-delay="50"
                data-aos-duration="1000"> */}
            <Button variant='contained'>
                ICSE
            </Button>
            <Button variant='contained'>
                IGCSE
            </Button>
            <Button variant='contained'>
                ISC
            </Button>
            <Button variant='contained'>
                A-Levels
            </Button>
            <Button variant='contained'>
                IBDP
            </Button>
        {/* </div> */}
            </AnimatedOnScroll>
    </div>
  )
}
