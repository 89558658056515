import React from 'react'
import "../style/home.css"
import { Typography } from '@mui/material'

export default function TitleSection({title}) {
  return (
    <div>
        <div className='tit_continer'>
        <Typography variant="h2" className='title_of_section'>{title}</Typography>
        </div>
    </div>
  )
}
