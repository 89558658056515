import React from "react";
// import TitleSection from "./TitleSection";
// import { Typography } from "@mui/material";
import "../style/home.css";
// import fis_img1 from "../images/students_banner.jpeg";
// import fis_img2 from "../images/parents_banner.jpeg";
// import fis_img3 from "../images/student_2.jpg";
// import fis_banner from "../images/resposn_banner.jpeg";
import fis_banner1 from "../images/IGF Values-01.jpg";
import fis_banner2 from "../images/IGF Values-02.jpg";
import fis_banner3 from "../images/IGF Values-03.jpg";
import fis_banner4 from "../images/IGF Values-04.jpg";
import fis_banner5 from "../images/IGF Values-05.jpg";
import Slider from "react-slick";
// import side_img from "../images/sec_icon.png";
import { AnimatedOnScroll } from "react-animated-css-onscroll";

export default function FisHall() {
  // var settings = {
  //   autoplay: true,
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   // arrow: true
  // };

  var settings2 = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrow: true
  };
  return (
    <>
      {/* <div className="Fisofhall_main" id="FIS_id">
        <div className="sec_icon_right">
          <img src={side_img} alt="side logo" />
        </div>
        <TitleSection title={"FIS Hall Of Fame"} />
        <div className="fall_main_cont">
          <AnimatedOnScroll
            animationIn="fadeInLeft"
            animationInDelay={50}
            animationInDuration={1000}
            className="fis_hall_cont"
          >
            
            <div className="fis_img_div">
              <Slider {...settings}>
                <div className="fis_img_div">
                <img src={fis_img1} alt="some new containt" />
                </div>

                <div className="fis_img_div">
                <img src={fis_img2} alt="some new containt" />
                </div>
              </Slider>
            </div>
            <Typography variant="h3">Parents</Typography>
            
          </AnimatedOnScroll>

          <AnimatedOnScroll
            animationIn="fadeInRight"
            animationInDelay={50}
            animationInDuration={1000}
            className="fis_hall_cont"
          >
           
            <div className="fis_img_div">
              <Slider {...settings}>
                <div className="fis_img_div">
                <img src={fis_img1} alt="some new containt" />
                </div>

                <div className="fis_img_div">
                <img src={fis_img3} alt="some new containt" />
                </div>
              </Slider>
            </div>
            <Typography variant="h3">Students</Typography>
          
          </AnimatedOnScroll>
        </div>
      </div> */}
      <AnimatedOnScroll
        animationIn="fadeInUp"
        animationInDelay={50}
        animationInDuration={1000}
        className="fis_banner_cont"
      >
        {/* <div className='fis_banner_cont' data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="1000"> */}
              <Slider {...settings2}>

        {/* <img src={fis_banner} alt="some new containt" /> */}
        <div className="img_last_banner">
        <img src={fis_banner1} alt="some new containt" />
        </div>

        <div className="img_last_banner">
        <img src={fis_banner2} alt="some new containt" />
        </div>

        <div className="img_last_banner">
        <img src={fis_banner3} alt="some new containt" />
        </div>

        <div className="img_last_banner">
        <img src={fis_banner4} alt="some new containt" />
        </div>

        <div className="img_last_banner">
        <img src={fis_banner5} alt="some new containt" />
        </div>
        </Slider>
        {/* <AnimatedOnScroll
          animationIn="fadeInDown"
          animationInDelay={50}
          animationInDuration={1000}
          className="fall_hall_title"
        >
          <Typography variant="h3">Responsibility for learning</Typography>
          <Typography variant="h4">
            We are all learners for life.We trust the professionalism of our
            teachers and we trust our students to be proactive learners.
          </Typography>
        </AnimatedOnScroll> */}
      </AnimatedOnScroll>
    </>
  );
}
