import React from 'react'
import { Typography } from '@mui/material'
import TitleSection from './TitleSection'
// import imgs_1 from "../images/school_premi.jpg"
// import imgs_1_school from "../images/infra_img1.jpg"
import imgs_1_thane_school from "../images/Thane School.jpg"
import imgs_2 from "../images/interactive.jpg"
import imgs_3 from "../images/infra_img3.jpg"
import imgs_4 from "../images/sci_lab.jpg"
import imgs_5 from "../images/swimming.png"
import imgs_6 from "../images/students_dinning.jpg"
import imgs_7 from "../images/library_rom.jpg"
import imgs_8 from "../images/recreation.png"
import side_img from "../images/sec_icon.png"
import { AnimatedOnScroll } from "react-animated-css-onscroll";


export default function Infrastructure() {
  return (
    <div className='infra_main_cont' id='Infrastructure_id'>
        <div className='sec_icon_right'>
            <img src={side_img} alt="side logo"/>
        </div>
        <TitleSection title={"Infrastructure"}/>
        <AnimatedOnScroll
                animationIn="fadeInUp"
                animationInDelay={50}
                animationInDuration={1000}
                className='all_infra_div'
            >
        {/* <div className='all_infra_div'
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="1000"
                > */}
            <div className='infra_img_div'>
                <img src={imgs_1_thane_school} alt='some new containt' />
                <Typography variant='h4'>
                School Premises
                </Typography>
            </div>

            <div className='infra_img_div'>
                <img src={imgs_2} alt='some new containt' />
                <Typography variant='h4'>
                Interactive Classrooms
                </Typography>
            </div>

            <div className='infra_img_div'>
                <img src={imgs_3} alt='some new containt' />
                <Typography variant='h4'>
                High-tech Computer Labs
                </Typography>
            </div>

            <div className='infra_img_div'>
                <img src={imgs_4} alt='some new containt' />
                <Typography variant='h4'>
                Advanced Science Labs
                </Typography>
            </div>

            <div className='infra_img_div'>
                <img src={imgs_5} alt='some new containt' />
                <Typography variant='h4'>
                Dedicated Swimming Area
                </Typography>
            </div>

            <div className='infra_img_div'>
                <img src={imgs_6} alt='some new containt' />
                <Typography variant='h4'>
                Dinning room for 400 kids
                </Typography>
            </div>

            <div className='infra_img_div'>
                <img src={imgs_7} alt='some new containt' />
                <Typography variant='h4'>
                Library
                </Typography>
            </div>

            <div className='infra_img_div'>
                <img src={imgs_8} alt='some new containt' />
                <Typography variant='h4'>
                Recreaction Area
                </Typography>
            </div>
        {/* </div> */}
        </AnimatedOnScroll>
    </div>
  )
}
